<template>
  <basic-container>
    <el-popover
      v-if="!hideApproval"
      width="400"
      ref="popover"
      trigger="manual"
      :width="popoverWidth"
      @show="openPopover"
      @hide="closePopover"
      v-model="popoverShow">
      <div class="selectContent">
        <el-input v-model="searchValue" placeholder="请输入内容" @input="inputSearch"></el-input>
        <div class="selectModel" :style="{color:selectActive == index?themeColor:''}" v-for="(item,index) in selectModelList" :key="item.id" @click="chooseSelct(item,index)">
          {{item.name}}
        </div>
        <div class="line"></div>
        <el-button type="primary" icon="el-icon-circle-plus-outline" class="addBtn" @click="addMore"></el-button>
      </div>
      <el-button slot="reference" class="approvalBtn" :disabled="type == 'view'"  @click="popoverShow = true">请选择常用批复意见</el-button>
    </el-popover>
    <avue-form v-model="examineForm"
               ref="examineForm"
               :option="examineOption">
    </avue-form>

    <approval-opinion-dialog
      ref="approvalOpinionDialog"
      @closeDialog="getOpinionList">
    </approval-opinion-dialog>
  </basic-container>
</template>

<script>
import {getList} from "@/api/plugin/workflow/process/approval-opinion-api";
import ApprovalOpinionDialog from "@/views/plugin/workflow/process/components/approvalOpinionDialog";
import {mapGetters} from "vuex";
export default {
  name: 'wf-exam-form',
  components: {ApprovalOpinionDialog},
  props: {
    nextTasks: {
      type: Array,
      default: () => {
        return []
      }
    },
    process: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type:{
      type: String,
      default: '',
    },
    hideApproval: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    process: {
      handler(val) {
        if (!val) return
        const { hideComment, hideAttachment, hideCopy, hideExamine, copyUser, copyUserName } = val
        if (hideComment) this.findObject(this.examineOption.column, 'comment').display = false // 隐藏评论
        if (hideComment || hideAttachment) this.findObject(this.examineOption.column, 'attachment').display = false // 隐藏评论附件
        if (hideCopy) this.findObject(this.examineOption.column, '$copyUser').display = false // 隐藏抄送人
        if (copyUser) this.$set(this.examineForm, 'copyUser', val.copyUser) // 默认抄送人
        if (copyUserName) this.$set(this.examineForm, '$copyUser', val.copyUserName) // 默认抄送人
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      examineForm: {
        assignee:{},
      },
      popoverShow: false,
      searchValue:'',
      selectModelList:[],
      selectMainList:[],
      selectActive: null,
      examineOption: {
        menuBtn: false,
        labelWidth: 90,
        labelPosition:"right",
        column: [
          {
            label: '',
            prop: 'comment',
            type: 'textarea',
            disabled: !['unfinished'].includes(this.process.processIsFinished),
            span: 24,
            maxlength: 200,
            placeholder: "请输入批复意见",
            event: {
              change: (val) => {
                this.$emit('update:comment', val)
              }
            },
            display: true,
          },
          {
            label: '附件',
            prop: 'attachment',
            type: 'upload',
            disabled: !['unfinished'].includes(this.process.processIsFinished),
            propsHttp: {
              res: 'data',
              url: 'link',
              name: 'originalName'
            },
            props: {
              label: 'name',
              value: 'url'
            },
            action: '/api/sinoma-resource/oss/endpoint/put-file',
            span: 24,
            display: true,
            uploadPreview: (file, column, done) => {
              window.open(file.url, "_blank");
              return;
            }
          },
          // {
          //   label: '抄送人',
          //   prop: '$copyUser',
          //   placeholder: '请选择 抄送人',
          //   readonly: true,
          //   disabled: !['unfinished'].includes(this.type),
          //   append: '+',
          //   span: 24,
          //   event: {
          //     click: () => {
          //       this.$emit('user-select', { type: 'copy', checkType: 'checkbox' })
          //     }
          //   },
          //   display: true
          // },

        ]
      },
      popoverWidth: "auto",
    }
  },
  computed: {
    ...mapGetters(["themeColor"]),
  },
  mounted() {
    this.examineOption.column.push(...this.nextTasks)
    this.getOpinionList();
  },
  methods: {
    getComment() {
      return this.examineForm.comment
    },
    openPopover() {
      this.popoverWidth = this.$refs.popover.$el.offsetWidth
      document.addEventListener('click', this.hidePopover, false)
    },
    closePopover() {
      document.removeEventListener('click', this.hidePopover, false)
    },
    hidePopover(e) {
      if (!this.$refs.popover.$el.contains(e.target)) {
        this.popoverShow = false;
        this.searchValue = '';
      }
    },
    addMore() {
      this.$refs.approvalOpinionDialog.openDialog();
    },
    getOpinionList(){
      getList().then((res)=>{
        this.selectModelList = res.data.data;
        this.selectMainList = res.data.data;
      });
    },
    inputSearch(e) {
      let result = [];
      this.selectMainList.forEach((item) =>{
        if (item.name.indexOf(e) != -1) {
          result.push(item);
          this.selectModelList = result;
        }
      })
    },
    chooseSelct(item,index) {
      this.selectActive = index;
      this.examineForm.comment = item.name;
      this.popoverShow = false;
    }
  }
}
</script>

<style scoped lang='scss'>
.allNameBox {
  cursor: pointer !important;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  position: absolute;
  left: 2px;
}
.selectContent {
  width: 100%;
}

.selectModel {
  width: 100%;
  height: 36px;
  line-height: 36px;
  color: #333333;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 10px;
}

.selectModel:hover {
  background-color: #f5f7fa;
}

.selectActive {
  color: #2979FF;
}

.line {
  border-top: 1px dashed #303133;
  width: 100%;
  margin: 6px 0;
}

.addBtn{
  width: 100%;
}
::v-deep .addBtn i {
  font-size: 22px;
}

.approvalBtn {
  margin-left: 5px;
  text-align: center;
  margin-bottom: 5px;
}

.topGrey {
  width: calc(100% - 12px);
  background-color: #F7F7F7;
  height: 40px;
  margin: 0 5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #DCDFE6;
  border-bottom: none;
}

::v-deep .avue-form__group .avue-form__row:first-child .el-form-item .el-form-item__content  {
  margin-left: 0px !important;
}

::v-deep .avue-form__group .avue-form__row:first-child .el-form-item .el-form-item__content .el-textarea .el-textarea__inner {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

// 流程详情不随主题色改变
.approvalBtn:hover {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.approvalBtn:focus {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

::v-deep .el-button--primary {
  color: #FFF;
  background-color: #409EFF;
  border-color: #409EFF;
}

::v-deep .el-button:hover {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

::v-deep .el-button:focus {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

::v-deep .el-textarea__inner:focus {
  border-color: #409EFF;
}

::v-deep .el-input__inner:focus {
  border-color: #409EFF;
}
</style>
